import { useEffect } from 'react';

import { type ModelsNotificationSettings } from '@lp-lib/api-service-client/public';

import { useUserAnalytics } from '../../analytics/user';
import { GlobalLoading } from '../../components/GlobalLoading';
import { LPNavLogo } from '../../components/icons/LPLogo';
import {
  NotificationForm,
  useNotificationSettings,
} from '../../components/Settings/Notifications';
import { useUser } from '../../components/UserContext';
import { useQueryParam } from '../../hooks/useQueryParam';
import { apiService } from '../../services/api-service';
import { fromDTOUser } from '../../utils/api-dto';

// eslint-disable-next-line import/no-default-export
export default function Home(): JSX.Element {
  const payload = useQueryParam('payload');
  const signature = useQueryParam('signature');
  const user = useUser();
  const userAnalytics = useUserAnalytics();

  const { data, isLoading, mutate } = useNotificationSettings({
    payload: payload || undefined,
    signature: signature || undefined,
  });

  // identify user if not logged in
  useEffect(() => {
    if (!!user.id) return;
    if (!data?.user) return;

    userAnalytics.identify(fromDTOUser(data.user));
    return () => {
      userAnalytics.unidentify();
    };
  }, [data?.user, user, userAnalytics]);

  if (isLoading || !data) return <GlobalLoading />;

  const handleSubmit = async (value: ModelsNotificationSettings) => {
    await apiService.notification.UpdateSettings({
      payload: payload || undefined,
      signature: signature || undefined,
      value,
    });
    await mutate();
  };

  return (
    <div className='w-full h-full bg-black flex flex-col'>
      <header className='w-full h-20 border-secondary border-b flex items-center z-40'>
        <div className='h-full flex-none flex items-center gap-7.5'>
          <div className='flex-none'>
            <LPNavLogo />
          </div>
        </div>
      </header>

      <main className='w-full flex-1 overflow-y-auto scrollbar text-white p-5 lg:p-10'>
        <div>
          <h1 className='text-2xl font-medium'>Notification Settings</h1>
          <p className='text-icon-gray'>{data.user.email}</p>
        </div>
        <div className='ml-5 mt-10'>
          <NotificationForm
            initEditing
            settings={data.settings}
            features={data.features}
            onSubmit={handleSubmit}
          />
        </div>
      </main>
    </div>
  );
}
